/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable no-var */
/* eslint-disable no-console */
import { useRecoilState, useSetRecoilState } from 'recoil';
import { SignIn } from './views';
import { DOMAttributes, useCallback, useEffect, useRef } from 'react';
import {
	AuthenticatedState,
	FacialPropsState,
	SignInActiveStepState,
	jackActiveStepsState,
} from './states/signin';
import 'remixicon/fonts/remixicon.css';
import './App.css';
import { FaceDetection, FaceRecognition, useFace, useNetwork } from 'hooks';
import {
	GenerateAPIcallState,
	RecipientImagesArrayState,
	RecipientImageState,
	recipitentDetailsResponseState,
	recipitentDetailsState,
	senderDetailsState,
	SenderImageState,
	// userLocationState,
} from 'states';
import {
	REACT_APP_NAME as name,
	REACT_APP_MOBILE_NUMBER as phNumber,
	REACT_APP_API_HOST as API_HOST,
} from 'envs';
import { API_URL } from 'constant';
import { VerificationAuth } from 'views/verification-auth/verification-auth';
import { jackTranstionKeys } from 'views/verification-auth/states/jackstate';

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;

declare global {
	namespace JSX {
		interface IntrinsicElements {
			['simplici-verfication']: CustomElement<any>;
		}
	}
}

const App = (props: any) => {
	console.log('props------->', props);
	const { get, post } = useNetwork();
	const { setupCanvas } = useFace();
	const setSignInActiveStep = useSetRecoilState(SignInActiveStepState);
	const setFacialPropsState = useSetRecoilState(FacialPropsState);
	const setSenderDetails = useSetRecoilState(senderDetailsState);
	const setAuthenticated = useSetRecoilState(AuthenticatedState);
	const setSenderImage = useSetRecoilState(SenderImageState);
	const setRecipientImage = useSetRecoilState(RecipientImageState);
	const [recipientArray, setRecipientArray] = useRecoilState(
		RecipientImagesArrayState
	);
	const setActiveJackstep = useSetRecoilState(jackActiveStepsState);
	const setJackTranstionKeys = useSetRecoilState(jackTranstionKeys);

	// using this state for loader
	const setRecipitentDetailsResponse = useSetRecoilState(
		recipitentDetailsResponseState
	);
	// using this state for get details of recipient
	const setRecipitetentDetails = useSetRecoilState(recipitentDetailsState);
	const [generatesValue, setGeneratesValue] =
		useRecoilState(GenerateAPIcallState);

	const genrateValueRef = useRef(generatesValue);

	useEffect(() => {
		genrateValueRef.current = generatesValue;
	}, [generatesValue]);

	const getSenderEmbeddings = useCallback(() => {
		setSignInActiveStep('chooseMethod');
		///biometrics/get
		post('/biometrics/get?type=arc-facial', {
			phone: props?.mobileNumber ?? '8126880650',
			countryCode: props?.countryCode ?? '+91',
		})
			.then((data) => {
				if (data) {
					FaceRecognition.setupModal(data);
				}
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log('Error', err);
			});
	}, [props]);

	console.log('generatesValue--------->', genrateValueRef.current);
	const genrateTranction = useCallback(() => {
		if (genrateValueRef.current === false) {
			const fbAmount = (
				document
					?.querySelector(
						'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
					)
					?.shadowRoot?.querySelector?.('#frmEnterAmount')
					?.shadowRoot?.querySelector?.('#amount') as
					| HTMLInputElement
					| undefined
			)?.value?.toString();
			const boaAmount = (
				document.getElementById('txtAmount') as HTMLInputElement | undefined
			)?.value?.toString();
			let personName = name;
			let phoneNumber = phNumber;
			let email: any = '';
			try {
				personName =
					document
						?.querySelector(
							'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
						)
						?.shadowRoot?.querySelector('#frmEnterAmount')
						?.shadowRoot?.querySelector('form > h2')
						?.textContent?.replace('Send to ', '') ?? name;
				if (/John/gi.test(personName)) {
					phoneNumber = '+16122401819';
					email = 'johnnywhitaker@gmail.com';
				} else if (/Abhishek/gi.test(personName)) {
					phoneNumber = '+918939155935';
					email = 'Abhishek.Mahra@satschel.com';
				} else if (/Alina/gi.test(personName)) {
					if (API_HOST.includes('pp')) {
						phoneNumber = '+918586808804';
						email = 'vaishali.jain@satschel.com';
					} else {
						phoneNumber = '+14157416782';
						email = 'alina.trombley@satschel.com';
					}
				} else if (/Jim/gi.test(personName)) {
					phoneNumber = '+16127207022';
					email = 'jim.delaney@satschel.com';
				} else {
					if (props.isVerified) {
						phoneNumber = '+918283847779';
						email = 'arun2611998@gmail.com';
					} else {
						phoneNumber = phNumber;
						email = 'Alina.trombley@satschel.com';
					}
				}
			} catch (error) {
				console.log('error', error);
			}
			console.log('phoneNumber', phoneNumber);
			const amount = fbAmount ?? boaAmount ?? '1';
			const payload = {
				sender: {
					phone: props?.countryCode + props?.mobileNumber ?? '+918126880650',
					notification: {
						email: true,
						phone: true,
					},
					liveness: {
						gestures: [
							{
								id: 7,
								name: 'Smile',
								type: 'mouthSmileRight',
								video:
									'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/Smile.mp4',
								image: 'NA',
								description: 'NA',
								threshold: 0.6,
								frequency: 1.0,
							},
							{
								id: 2,
								name: 'Open Mouth',
								type: 'jawOpen',
								video:
									'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/Open_mouth.mp4',
								image: 'http://surl.li/nruox',
								description: 'NA',
								threshold: 0.2,
								frequency: 1.0,
							},
						],
					},
					face_match: false,
					order: 2,
				},
				recipient: {
					phone: phoneNumber ?? '+918283847779',
					name: personName ?? 'Abhishek Mehra',
					email: email,
					notification: {
						email: true,
						phone: true,
					},
					liveness: {
						gestures: [
							{
								id: 7,
								name: 'Smile',
								type: 'mouthSmileRight',
								video:
									'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/Smile.mp4',
								image: 'NA',
								description: 'NA',
								threshold: 0.6,
								frequency: 1.0,
							},
							{
								id: 2,
								name: 'Open Mouth',
								type: 'jawOpen',
								video:
									'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/Open_mouth.mp4',
								image: 'http://surl.li/nruox',
								description: 'NA',
								threshold: 0.2,
								frequency: 1.0,
							},
						],
					},
					face_match: false,
					order: 1,
				},
				amount: amount ?? 1,
				callbackUrl: 'https://google.co.in/',
			};
			const apiUrl = 'https://api.stage.satschel.com/v2/rocket/generates/';
			const headers = {
				'Content-Type': 'application/json',
				// Include any additional headers if needed
			};
			fetch(apiUrl, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify(payload),
			})
				.then((response) => {
					// Check if the request was successful (status code 200)
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then((responseData) => {
					// Update the state with the response data
					console.log('POST request successful!', responseData);
					setJackTranstionKeys(responseData);
					setActiveJackstep('waiting-screen');
				})
				.catch((error) => {
					console.error('Error making POST request:', error.message);
				});
		}
	}, []);

	useEffect(() => {
		let button: any = null;
		if (props.isVerified && genrateValueRef.current === false) {
			button = document
				?.querySelector(
					'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
				)
				?.shadowRoot?.querySelector('#frmEnterAmount')
				?.shadowRoot?.querySelector('form > footer > jha-button');
			if (button && !genrateValueRef.current) {
				button.addEventListener('click', (e: any) => {
					genrateTranction();
					genrateValueRef.current = true;
					setGeneratesValue(true);
					e.preventDefault();
				});
			}
		}
	}, []);

	useEffect(() => {
		setFacialPropsState({ ...props });
		// Need to hide
		// if (props.isVerified) {
		// 	genrateTranction();
		// }
		if (!props.isVerified && props.fromBank) {
			setSignInActiveStep('chooseMethod');
			getSenderEmbeddings();
		}
		if (!props.isVerified && props.is_recipient) {
			setSignInActiveStep('recipient-approval');
		}
		if (props.recipient_status !== '') {
			get(`${API_URL.REQUEST_RECIPIENT + '/' + props.recipient_status}`)
				.then((resp) => {
					setRecipitetentDetails(resp.data);
				})
				.catch((error) => error);
			setSignInActiveStep('sender-check');
		}
	}, [props]);
	const loadModel = async () => {
		FaceRecognition.loadModal();
	};

	const capture = (video: any, scaleFactor: any) => {
		if (scaleFactor == null) {
			scaleFactor = 1;
		}
		var w = video.videoWidth * scaleFactor;
		var h = video.videoHeight * scaleFactor;
		var canvas = document.createElement('canvas');
		canvas.width = w;
		canvas.height = h;
		var ctx = canvas.getContext('2d');
		if (ctx) {
			ctx.drawImage(video, 0, 0, w, h);
		}
		return canvas;
	};

	useEffect(() => {
		// TODO @ARUN: if data is matched... show next button
		FaceRecognition.emitter.on('faceMatch', async (data: boolean) => {
			// console.log('faceMatchData', data);
			if (!props.is_recipient) {
				setAuthenticated(data);
			}
		});

		FaceRecognition.emitter.on('senderImg', async (base64Image: string) => {
			if (base64Image && !props.is_recipient) {
				// //initial-canvas-output
				// const canv: any =
				// 	document.getElementById('output') ??
				// 	document.getElementById('initial-canvas-output');
				// const base64 = canv?.toDataURL();
				// if (!props.is_recipient) {
				// 	setSenderImage(base64);
				// }
				// setTimeout(() => {
				var video = document.getElementById('video');
				var canvas = capture(video, 0.8);
				// 		// eslint-disable-next-line no-console
				// console.log('sender img ------', canvas.toDataURL());
				setSenderImage((prev: any) => (prev ? prev : canvas.toDataURL()));

				FaceDetection.recipientCheck = false;
				// }, 5000);
			}
		});

		//senderImg1
		FaceRecognition.emitter.on('senderImg1', async (base64Image: string) => {
			if (base64Image.length > 1000 && props.is_recipient) {
				var video = document.getElementById('video');
				var canvas = capture(video, 0.8);
				// setTimeout(() => {
				// if(recipientImg !== ""){
				// 		// eslint-disable-next-line no-console
				// 		// console.log('snapsshots', canvas);
				setRecipientImage((prev: any) => (prev ? prev : canvas.toDataURL()));

				FaceDetection.recipientCheck = false;
				// }
				// }, 2000);
			}
			// Your interval logic
		});
	}, [FaceRecognition]);

	useEffect(() => {
		setSenderDetails(props?.senderDetails);
		setRecipitentDetailsResponse(props?.checkRecipitentResp);
	}, [props]);

	useEffect(() => {
		if (props.is_recipient) {
			const getUserImages = setInterval(() => {
				var video = document.getElementById('video');
				var canvas = capture(video, 0.8);
				setRecipientArray((prev: any) => [...prev, canvas.toDataURL()]);
			}, 1000);
			if (recipientArray.length >= 20) {
				console.log('called');
				clearInterval(getUserImages);
			}

			return () => {
				clearInterval(getUserImages);
			};
		}
		return;
	}, [recipientArray]);

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				// Location was successfully retrieved.
				const { latitude, longitude } = position.coords;
				console.log(
					'Permission enable',
					`Latitude: ${latitude}, Longitude: ${longitude}`
				);
			},
			(error) => {
				// There was an error while retrieving the location.
				console.error('Error getting location:', error);

				if (error.code === error.PERMISSION_DENIED) {
					// The user denied location permission. You can prompt them to enable it.
					console.log(
						'Location permission is denied. Please enable it in your device settings.'
					);
				} else {
					// Handle other errors as needed.
					console.log(
						'Error getting location. Please check your device settings.'
					);
				}
			}
		);
	}, []);

	useEffect(() => {
		if (props.is_recipient) {
			FaceDetection.isRecipient = true;
		}
		if (!props.isVerified) {
			FaceDetection.init();
			if (!props.is_recipient) loadModel();
			const CameraInterval = setInterval(() => {
				if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
					navigator.mediaDevices
						.getUserMedia({ video: true })
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						.then(function (stream) {
							// Camera access granted
							let size: number;
							if (window.innerWidth <= 760) {
								size = 350;
							} else {
								size = 450;
							}
							setupCanvas(size);
							clearInterval(CameraInterval);
							if (props.fromBank) {
								setSignInActiveStep(
									props.is_recipient ? 'recipient-approval' : 'chooseMethod'
								);
							} else if (
								props.recipient_status === '' &&
								!props.isrecipient_id
							) {
								setSignInActiveStep(
									props.is_recipient ? 'recipient-approval' : 'phone'
								);
							}

							// Remember to stop the stream if you no longer need it
							// console.log('stream', stream);
							stream.getTracks().forEach((track) => track.stop());
						})
						.catch(function (error) {
							// Camera access denied or error occurred
							// eslint-disable-next-line no-console
							console.error('Camera permission denied or error:', error);
							setSignInActiveStep('camera-settings');
						});
				} else {
					// getUserMedia API is not supported
					// eslint-disable-next-line no-console
					console.log('getUserMedia API is not supported');
				}
			}, 1000);
		}

		// navigator.permissions
		// 	.query({ name: 'camera' as any })
		// 	.then((permissionObj) => {
		// 		console.log('Camera', permissionObj.state);
		// 	})
		// 	.catch((error) => {
		// 		console.log('Got error Camera:', error);
		// 	});
	}, []);

	//const { setupCanvas } = useFace();

	// useEffect(() => {
	// let size: number;
	// if (window.innerWidth <= 760) {
	// 	size = 350;
	// } else {
	// 	size = 450;
	// }
	// // TODO: remove timeout and will fix (convas - null issue)
	// setTimeout(() => {
	// 	setupCanvas(size);
	// }, 1000);
	// TODO @ARUN: if data is matched... show next button
	// }, []);

	const handleFaceLoginSuccess = useCallback(async (payload: any) => {
		console.log('------------- JACK PAYLOAD ----->', payload);
		props.onSuccess(payload, true);
	}, []);

	return (
		<div className="App">
			{props?.isVerified ? (
				<VerificationAuth onSuccess={handleFaceLoginSuccess} />
			) : (
				<SignIn onSuccess={props.onSuccess} />
			)}

			<video
				id="video"
				playsInline
				style={{ display: 'none' }}
				// onLoadStart={handleOnLoadVideo}
			></video>

			<div className="canvas-wrapper" style={{ display: 'none' }}>
				<canvas
					style={{
						borderRadius: '18%',
						width: '96%',
						height: '96%',
						display: 'none',
					}}
					id="initial-canvas-output"
				></canvas>
			</div>
		</div>
	);
};

export default App;
